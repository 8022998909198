<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-toolbar>
            <v-toolbar-title> Add New Profile </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              label="Organisation Name"
              v-model="newProfile.organisationName"
            >
            </v-text-field>
            <v-text-field
              label="Authorised Email"
              v-model="newProfile.authorisedEmail"
            >
            </v-text-field>
            <v-text-field
              label="Tenant ID"
              v-model="newProfile.tenantId"
            ></v-text-field>
            <v-textarea label="Scopes" v-model="newProfile.scopes">
            </v-textarea>
            <v-text-field label="Client Id" v-model="newProfile.clientId">
            </v-text-field>
            <v-textarea label="Client Secret" v-model="newProfile.clientSecret">
            </v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="addProfile">
              <v-icon class="mr-2"> mdi-plus </v-icon>
              Add Profile
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="9">
        <trev-cool-list
          :items="orgs"
          title="Xero Organisation Profiles"
          titleIcon="mdi-cash-register"
          :pageSize="15"
          twoline
        >
          <template v-slot:repeaterItem="item">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.item.organisationName }} ({{
                  item.item.authorisedEmail
                }})
              </v-list-item-title>
              <v-list-item-subtitle>
                Client Id - {{ item.item.clientId }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn color="danger" @click="showDeleteOrg(item.item)">
                <v-icon class="mr-2" small> mdi-delete </v-icon>
                Remove Organisation
              </v-btn>
            </v-list-item-action>
          </template>
        </trev-cool-list>
      </v-col>
    </v-row>
    <trev-confirm-delete-dialog
      :itemToDelete="showingOrg.organisationName"
      :show="isShowing"
      maxWidth="60vh"
      @dialog-delete-deleted="deleteOrganisation"
      @dialog-delete-cancelled="cancelled"
    >
    </trev-confirm-delete-dialog>
  </v-container>
</template>

<script>
import TrevConfirmDeleteDialog from "../../../../components/base/dialog/trev-confirm-delete-dialog.vue";
import trevCoolList from "../../../../components/tables/trev-cool-list.vue";
export default {
  components: { trevCoolList, TrevConfirmDeleteDialog },
  created() {
    this.getOrgs().then((x) => {
      this.orgs = x.payload;
      this.orgCount = x.totalCount;
    });
  },
  methods: {
    addProfile() {
      this.$courier.XeroOrganisationProfiles.add(this.newProfile).then(() => {
        this.getOrgs().then((x) => {
          this.orgs = x.payload;
          this.orgCount = x.totalCount;
          this.clearProfile();
        });
      });
    },
    clearProfile() {
        this.newProfile = {};
    },
    deleteOrganisation() {
      this.$courier.XeroOrganisationProfiles.removeById(
        this.showingOrg.id
      ).then(() => {
        this.getOrgs().then((x) => {
          this.showingOrg = {};
          this.isShowing = false;
          this.orgs = x.payload;
          this.orgCount = x.totalCount;
        });
      });
    },
    cancelled() {
      this.showingOrg = {};
      this.isShowing = false;
    },
    async getOrgs() {
      return await this.$courier.XeroOrganisationProfiles.getData(1, 999, "");
    },
    showDeleteOrg(org) {
      this.showingOrg = org;
      this.isShowing = true;
    },
  },
  data() {
    return {
      orgs: [],
      orgCount: 0,
      showingOrg: {},
      isShowing: false,
      newProfile: {
        clientId: "",
        clientSecret: "",
        scopes: "",
        authorisedEmail: "",
        tenantId: "",
        organisationName: "",
      },
    };
  },
};
</script>

<style>
</style>